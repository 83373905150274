import React, {useMemo, useState} from 'react';
import {CenteredContainer} from "../styled"
import {ErrorMessage, TextInput, TextInputWrapper, Title} from "./styled";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from 'react-redux';
import {setFirstName, setEmail, setAccessToken, setError} from '../../../store/reducers/userSlice';
import {ButtonPrimary, WrapperContent} from "../../shared/GlobalComponents";

const Step4 = () => {

    const API_URL = process.env.REACT_APP_API_URL
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {firstName, email, error} = useSelector((state) => state.user);
    const {nace_code, groupCodes} = useSelector((state) => state.analysis);
    const [password, setPassword] = useState('')

    const [isNameValid, setIsNameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const handleButtonClick = async () => {
        try {
            dispatch(setFirstName(firstName));
            dispatch(setEmail(email));
            dispatch(setError(''));

            const response = await fetch(API_URL + `/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({firstName, email, password, nace_code, groupCodes}),
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.error;
                dispatch(setError("An error occurred: " + errorMessage));
                return;
            }

            const userData = await response.json();
            dispatch(setAccessToken(userData.access_token));

            navigate('/analysis/5');
        } catch (err) {
            dispatch(setError("An error occurred: " + err.message));
        }
    };

    const validateName = (name) => {
        return name.trim().length > 0;
    };

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        dispatch(setFirstName(value));
        setIsNameValid(validateName(value));
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        dispatch(setEmail(value));
        setIsEmailValid(validateEmail(value));
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setIsPasswordValid(validatePassword(value));
    };

    const isFormValid = useMemo(() => {
        return isNameValid && isEmailValid && isPasswordValid;
    }, [isNameValid, isEmailValid, isPasswordValid]);

    return (
        <WrapperContent>
            <CenteredContainer>
                <Title>Create FREE account to explore Materiality topics</Title>
                <TextInputWrapper>
                    <TextInput
                      type="text"
                      placeholder="Your first name"
                      value={firstName}
                      onChange={handleNameChange}
                    />
                    <TextInput
                      type="email"
                      placeholder="Company email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <TextInput
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                </TextInputWrapper>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <ButtonPrimary onClick={handleButtonClick} disabled={!isFormValid}>
                    Create a FREE account
                </ButtonPrimary>
            </CenteredContainer>
        </WrapperContent>
    );
};

export default Step4;