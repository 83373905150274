import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDoubleMateriality} from "../../store/reducers/assessmentSlice";
import useIntervalFetch from "../../hooks/useIntervalFetch";
import {ButtonPrimary, H1, H2, H3, H4, P, WrapperContent} from "../shared/GlobalComponents";
import {TABS} from "../../constants/tabConstants";
import SustainabilityMatters from "./SustainabilityMatters";
import ImpactsAndMateriality from "./ImpactsAndMateriality";
import DependenciesAndRisks from "./DependenciesAndRisks";
import Opportunities from "./Opportunities";
import ImpactMateriality from "./ImpactMateriality";
import FinancialMateriality from "./FinancialMateriality";

export const getSustainabilityMatterTopic = (item) => {
  return item?.generatedtopic ?? item?.subsubtopic ?? item?.subtopic ?? item?.topic;
};

function Assessment() {
  const REFRESH_INTERVAL = 10000;
  const dispatch = useDispatch();

  const assessementState = useSelector(state => state.assessment);

  const shouldRefresh = useCallback(() => {
    for (const results of Object.values(assessementState)) {
      if (results && Object.keys(results).length > 0) {
        for (const data of Object.values(results)) {
          if (data.processing) {
            return true;
          }
        }
      }
    }
    return false;
  }, [assessementState]);

  useIntervalFetch(dispatch, getDoubleMateriality, shouldRefresh, REFRESH_INTERVAL);

  return (
    <>
      <WrapperContent>
        <H1>Sustainability Assessment</H1>

        <div hidden style={{textAlign: "right"}}>
          <ButtonPrimary>↓ Download report</ButtonPrimary>
        </div>

        <H2>
          Executive Summary
        </H2>
        <P>
          This graph highlights the key ESRS sustainability topics that most likely need your attention & action. Double Materiality Assessment looks at each sustainability topic from two angles: how your company affects the world (Impact Materiality) and how changes in the world could affect your company's finances (Financial Materiality). You should evaluate these shortlisted sustainability matters with input from your stakeholders.
        </P>

        {/* Placeholder for Double Materiality graph + the list of Material Matters that are present on the graph */}

        <H2>
          Our Direct Operations
        </H2>
        <P>
          You're about to witness structured sustainability pre-assessment, drawing on the company information you've provided and leveraging data from prestigious inter-governmental scientific working groups.
        </P>

        <P>This chapter focuses on your company's direct operations. You will examine the upstream and downstream value chain in subsequent chapters.</P>

        <P>
          Our bottom-up approach follows these three steps to identify material matters for sustainability reporting and strategy development:
        </P>

        <ul>
          <li>Identifying the most likely impacts and risks</li>
          <li>Shortlisting sustainability topics </li>
          <li>Determining material matters through a double materiality assessment.</li>
        </ul>

        <P>
          After review, validation, and refinement by your stakeholders, the outcome will serve as a strategic management tool, guiding your company towards a sustainable, net-zero impact future.
        </P>

        {/* Include additional details based on NACE code, PRODCOM, and/or CN as applicable */}

        <H3>
          Impacts, Risks, Opportunities (IROs)
        </H3>
        <P>
          The process begins by pinpointing the potential impacts and risks related to your business activities, in accordance with the EU Corporate Sustainability Reporting Directive. 
        </P>

        <H4>
          We Impacting the World
        </H4>
        <P>
          Since your company doesn't operate in a vacuum, its activities affect both nature and people, in one way or another. Below is a list of the most common impacts that a business like yours might have on the outside world.
        </P>

        <ImpactsAndMateriality results={assessementState[TABS.GROUP]} />

        <H4>
          The World Impacts Us (Risks)
        </H4>

        <P>
          Just as your company affects the environment and society, external changes also impact your financial operations.
        </P>
        <P>
          Below are some potential risks on your company from external changes, including the consequences of your actions on the environment, legislative changes, and dependencies on critical resources like energy, skilled workforce, and ecosystem services."
        </P>

        <DependenciesAndRisks results={assessementState[TABS.GROUP]}/>

        <H4>Opportunities for Our Company</H4>
        <P>
          In this section, the previously mentioned impacts and risks have been transformed into potential opportunities. Some of these opportunities are for the long term, but there are also actions you can begin to consider today. This is a starting point for you and your stakeholders to brainstorm and plan your journey to achieving net zero by 2050.
        </P>

        <Opportunities results={assessementState[TABS.GROUP]}/>

        <H3>Sustainability Matters</H3>
        <P>
          Here is a shortlist of ESRS sustainability topics. These include main topics, sub-topics, and more detailed sub-sub-topics from the European Sustainability Reporting Standards (ESRS). Please review this list carefully to ensure no critical topic is overlooked.
        </P>

        <SustainabilityMatters results={assessementState[TABS.GROUP]}/>

        <H3>
          Double Materiality Assessment
        </H3>
        <P>
          The Double Materiality Assessment is a key concept of the EU's Corporate Sustainability Reporting Directive. It involves examining each preselected sustainability topic from two important viewpoints:
        </P>

        <ol>
          <li>How much your company operations affects the sustainability topic - Impact Materiality</li>
          <li>How much each topic could affect your company's financial health - Financial Materiality</li>
        </ol>

        <H4>
          Impact Materiality
        </H4>
        <P>
          Here, every identified sustainability topic is presented in two dimensions: the likelihood of the impact occurring and the severity of the impact in case it occurs. The aggregated score of these two dimensions is called the Impact Materiality Score.
        </P>

        <ImpactMateriality results={assessementState[TABS.GROUP]}/>

        <H4>
          Financial Materiality
        </H4>
        <P>
          Similarly, each sustainability topic is analyzed from two additional dimensions: the likelihood of the risk materializing and the magnitude of the effect the risk can have on our financial performance. The combined score of these two dimensions is called the Financial Materiality Score.
        </P>

        <FinancialMateriality results={assessementState[TABS.GROUP]}/>

        <H4>
          Double Materiality Map
        </H4>
        <P>
          The ESRS sustainability topics displayed in this graph are termed Material Matters because they are significant from either an Impact Materiality perspective, a Financial Materiality perspective, or both. These matters require your attention and action.
        </P>

        <P>
          To clarify, Impact Materiality assesses the significance of your company's activities on nature and people. Financial Materiality, on the other hand, evaluates how significantly your company's financial performance could be affected by the ever-changing world and its various aspects.
        </P>

        <P>
          You should further evaluate and assess these shortlisted sustainability matters with your stakeholders and then use them in your business strategy creation.
        </P>

        {/* Placeholder for Double Materiality Graph + the list of Material Matters that are present on the graph */}

        <H2>
          Upstream Value Chain
        </H2>
        <P>
          Explore the upstream value chain to understand how your company's suppliers and their activities impact sustainability matters. This section evaluates the supply chain from raw materials to production processes.
        </P>

        {/* Placeholder for Upstream Value Chain analysis (NACE by NACE) */}

        <H2>
          Downstream Value Chain
        </H2>
        <P>
          Assess the downstream value chain to gauge the impact of your company's products and services from distribution to end users. This analysis includes lifecycle assessments and end-of-life management of products.
        </P>

        {/* Placeholder for Downstream Value Chain analysis (NACE by NACE) */}

        {/* Further sections or closing remarks can be added here following the same pattern. */}

      </WrapperContent>
    </>
  );
}

export default Assessment;