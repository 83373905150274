import styled from 'styled-components';

export const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: space-between; /* This will create some space between the two columns */
`;

export const Column = styled.div`
  flex: 1; /* This ensures that each column takes up equal width */
  padding: 20px; /* Some padding around each column's content */
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

export const CenteredContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 800px;
`;