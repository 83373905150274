import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {CenteredContainer, Label} from "../styled";
import {ButtonPrimary, H1, WrapperContent} from "../../shared/GlobalComponents";
import {setCountry} from "../../../store/reducers/analysisSlice";
import {useDispatch} from "react-redux";
import CountryPicker from "../../CountryPicker";

const Step2 = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState('EE');

  const handleNext = async () => {
    dispatch(setCountry(selectedCountry));

    try {
      navigate("/analysis/3");
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <WrapperContent>
      <CenteredContainer>
        <H1>The company is headquartered in</H1>
        <Label htmlFor="country">Select a country:</Label>
        <CountryPicker value={selectedCountry} setSelectedCountry={setSelectedCountry}/>
        <ButtonPrimary type="button"
                value="submit"
                onClick={handleNext}>Let's Analyze</ButtonPrimary>
      </CenteredContainer>
    </WrapperContent>
  )
}

export default Step2;