import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavLink = ({ path, children }) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  return <StyledLink to={path} className={isActive ? 'active' : ''}>{children}</StyledLink>;
};

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colorTextMain};
  text-decoration: none;
  padding: 0.25rem .5rem;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.colorGray100};
  }

  &.active {
    border-bottom: 4px solid;
    border-color: ${({ theme }) => theme.colorPrimary};
  }
`;

export default NavLink;
