import { StyledNavBar, Container, NavLinks, Logo, BackgroundWrapper } from './styled';
import logo from '../../assets/scapelypse-logo-black.png';
import {useSelector} from "react-redux";
import NavLink from "../shared/NavLink";
import { Link } from 'react-router-dom';
import {WrapperContent} from "../shared/GlobalComponents";

function NavBar() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <BackgroundWrapper>
      <StyledNavBar>
        <WrapperContent style={{background: "transparent", paddingBottom: "0"}}>
            <Container>
              <Link to="/assessment" className='wrapper-logo'>
                <Logo src={logo} alt="Scapelyse" />
              </Link>
              <NavLinks>
                {isLoggedIn ? (
                  <>
                    {/*TODO - for debugging, delete when we clean up this component*/}
                    {/*<NavLink path="/results">Results</NavLink>*/}
                    {/*<NavLink path="/dashboard">Dashboard</NavLink>*/}
                    <NavLink path="/assessment">Assessment</NavLink>
                    <NavLink path="/profile">Company</NavLink>
                    <NavLink path="/account">Account</NavLink>
                  </>
                ) : (
                  <NavLink path="/login">Login</NavLink>
                )}
              </NavLinks>
            </Container>
        </WrapperContent>
        </StyledNavBar>
      </BackgroundWrapper>
  );
}

export default NavBar;