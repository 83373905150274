import React from "react";
import {MaterialityBadge, P} from "../shared/GlobalComponents";
import {Table, TableBody, TableData, TableHeader, TableHeaderCell, TableHeaderRow, TableRow} from "./styled";
import EmptyTableRow from "./EmptyTableRow";

const DependenciesAndRisks = ({ results }) => {
  if (!results || Object.keys(results).length === 0) {
    return <P>No results.</P>;
  }
  return (
    <>
      {Object.entries(results).sort().map(([naceCode, data]) => {
        let dependencies = data.impacts_and_dependencies['dependencies']
        let risks = data.impacts_risks_opportunities['risks']
        let displayedCode = naceCode ? naceCode.slice(1, -1) : '';
        let description = data.impacts_and_dependencies['nace_rev2_description'];
        return (
          <>
            <P>NACE {displayedCode}: {description}</P>
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Likely Dependencies on Ecosystem Services</TableHeaderCell>
                  <TableHeaderCell>Likely Materiality</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {dependencies.length > 0 ? (
                  dependencies.map((item, index) => (
                    <TableRow key={index} isLastRow={index === dependencies.length - 1}>
                      <TableData>
                        {item.process}
                      </TableData>
                      <TableData>
                        <MaterialityBadge materiality={item.materiality}>
                          {item.materiality}
                        </MaterialityBadge>
                      </TableData>
                    </TableRow>
                  ))
                ) : (
                  <EmptyTableRow colSpan={2} isProcessing={data.processing}/>
                )}
              </TableBody>
            </Table>
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Risks</TableHeaderCell>
                  <TableHeaderCell>Likelihood</TableHeaderCell>
                  <TableHeaderCell>Term</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {risks.length > 0 ? (
                  risks.map((item, index) => (
                    <TableRow key={index} isLastRow={index === risks.length - 1}>
                      <TableData>{item.risk}</TableData>
                      <TableData>
                        <MaterialityBadge materiality={item.likelihood}>
                          {item.likelihood}
                        </MaterialityBadge>
                      </TableData>
                      <TableData>{item.term}</TableData>
                    </TableRow>
                  ))
                ) : (
                  <EmptyTableRow colSpan={3} isProcessing={data.processing}/>
                )}
              </TableBody>
            </Table>
          </>
        );
      })}
    </>
  );
};

export default DependenciesAndRisks;