import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import RequestStatus from "../../constants/RequestStatus";

const API_URL = process.env.REACT_APP_API_URL

const initialState = {
  group: {},
  downstream: {},
  upstream: {},
  requestStatus: RequestStatus.Idle,
};

export const getDoubleMateriality = createAsyncThunk(
  'getDoubleMateriality',
  async (args, thunkAPI) => {
    let API_ENDPOINT = API_URL + `/double-materiality`;

    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        return thunkAPI.rejectWithValue({ error: 'Access token not found'});
      }

      const response = await fetch(API_ENDPOINT, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return thunkAPI.rejectWithValue({ error: 'Network response was not ok'});
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data: ', error);
      return thunkAPI.rejectWithValue({ error: error});
    }
  }
);

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(getDoubleMateriality.pending, (state) => {
        state.requestStatus = RequestStatus.Loading;
      })
      .addCase(getDoubleMateriality.fulfilled, (state, action) => {
        state.group = action?.payload?.group;
        state.downstream = action?.payload?.downstream;
        state.upstream = action?.payload?.upstream;
        state.requestStatus = RequestStatus.Success;
      })
      .addCase(getDoubleMateriality.rejected, (state) => {
        state.requestStatus = RequestStatus.Failed;
      });
  },
});

export default assessmentSlice.reducer;
