import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Container} from "./styled";
import { localCountryData } from './countries';

const CountryPicker = ({value, setSelectedCountry}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
    .then((response) => {
        if (!response.ok) {
            throw new Error('API call failed');
        }
        return response.json();
    })
    .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
    })
    .catch((error) => {
        console.error("Error fetching countries:", error);
        setCountries(localCountryData.countries);
        // Set the first local country as the default if necessary
        if (!value) {
            setSelectedCountry(localCountryData.userSelectValue);
        }
    });
  }, [setSelectedCountry, value]);

  return (
    <Container>
      <Select
        options={countries}
        value={value}
        onChange={(selectedOption) => setSelectedCountry(selectedOption)}
      />
    </Container>
  );
};

export default CountryPicker;