import React from "react";
import {CartesianGrid, Scatter, ScatterChart, Tooltip, XAxis, YAxis} from "recharts";
import {getSustainabilityMatterTopic} from "./index";

const FinancialMaterialityGraph = ({ doubleMateriality }) => {
  const data = doubleMateriality.financial_materiality.map(item => ({
    sustainability_matter: getSustainabilityMatterTopic(item.sustainability_matter),
    likelihood_score: item.risk_likelihood_score,
    likelihood_reason: item.risk_likelihood_reason,
    magnitude_score: item.risk_magnitude_score,
    magnitude_reason: item.risk_magnitude_reason
  }));

  const renderCustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: "#fff", padding: ".5rem", border: "1px solid #ccc", boxShadow: "2px 2px 16px black" }}>
          <p><strong>{data.sustainability_matter}</strong></p>
          <p>Likelihood Score: {data.likelihood_score}</p>
          <p>{data.likelihood_reason}</p>
          <p>Magnitude Score: {data.magnitude_score}</p>
          <p>{data.magnitude_reason}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ScatterChart width={400} height={400} margin={{ top: 32, right: 0, bottom: 32, left: 0 }}>
      <CartesianGrid />
      <XAxis
        type="number"
        dataKey="likelihood_score"
        name="Likelihood Score"
        domain={[1, 10]}
        ticks={[1,2,3,4,5,6,7,8,9,10]}
        label={{ value: 'Risk Likelihood Score', position: 'bottom', offset: 0 }}
      />
      <YAxis
        type="number"
        dataKey="magnitude_score"
        name="Magnitude Score"
        domain={[1, 10]}
        ticks={[1,2,3,4,5,6,7,8,9,10]}
        label={{
          value: 'Risk Magnitude Score',
          angle: -90,
          position: 'insideLeft',
          offset: 20,
          style: {
            textAnchor: 'middle',
          }
        }}
      />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} content={renderCustomTooltip} />
      <Scatter name="Financial Materiality" data={data} fill="hsl(159, 100%, 30%)" />
    </ScatterChart>
  );
}

export default FinancialMaterialityGraph;