import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {getUser, updateUser} from "../../store/reducers/userSlice";
import {Label, TextInput} from "../Account/styled";
import {ButtonPrimary, H2} from "../shared/GlobalComponents";
import _ from 'lodash';

function CompanyInformation() {

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);

  const {naceCodes} = useSelector((state) => state.naceCodes);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [scales, setScales] = useState([]);
  const [headquarterLocation, setHeadquarterLocation] = useState('');
  const [operationLocations, setOperationLocations] = useState(['']);

  const [checkboxes, setCheckboxes] = useState({
    certificationsAndStandards: [],
    sustainabilityReporting: [],
    environmentalPractices: [],
    socialCommitments: [],
    governance: []
  });

  const handleCheckboxChange = (category, value) => {
    const updatedCheckboxes = {...checkboxes};

    // Ensure the category is initialized to an empty array if it's not already an array
    if (!Array.isArray(updatedCheckboxes[category])) {
      updatedCheckboxes[category] = [];
    }

    if (updatedCheckboxes[category].includes(value)) {
      updatedCheckboxes[category] = updatedCheckboxes[category].filter(item => item !== value);
    } else {
      updatedCheckboxes[category] = [...updatedCheckboxes[category], value];
    }

    setCheckboxes(updatedCheckboxes);

    dispatch(updateUser({
      sustainabilityPractices: updatedCheckboxes
    }));
  };


  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {

    const selected = userState.groupCodes.filter(Boolean).map(code => {
      const matchingCode = naceCodes.find(naceCode => naceCode.key === code);
      return matchingCode ? matchingCode : null;
    }).filter(code => code !== null);
    setSelectedCodes(selected);
    setWebsites(userState.websites || []);
    setScales(userState.scales || {});
    setHeadquarterLocation(userState.headquarterLocation || '');
    setOperationLocations(userState.operationLocations || []);
    setCheckboxes(userState.sustainabilityPractices || {});

  }, [userState, naceCodes]);

  // Use useRef to hold the debounced function
  const debouncedUpdateUserRef = useRef();

  useEffect(() => {
    // Initialize the debounced function once on component mount
    debouncedUpdateUserRef.current = _.debounce((updatedData) => {
      dispatch(updateUser(updatedData));
    }, 1000);

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedUpdateUserRef.current) {
        debouncedUpdateUserRef.current.cancel();
      }
    };
  }, [dispatch]);

  const handleChangeWebsite = (index, website) => {
    const updatedWebsites = {...websites, [index]: website};
    setWebsites(updatedWebsites);
    debouncedUpdateUserRef.current({ websites: updatedWebsites });
  };

  const handleChangeScale = (index, website) => {
    const updatedScales = {...scales};
    updatedScales[index] = website;
    setScales(updatedScales);

    dispatch(updateUser({
      scales: updatedScales
    }));

  };

  const handleChangeHeadquarterLocation = (e) => {
    setHeadquarterLocation(e.target.value);
    debouncedUpdateUserRef.current({headquarterLocation: e.target.value});
  };

  const handleChangeOperationLocation = (index, value) => {
    const updatedLocations = [...operationLocations];
    updatedLocations[index] = value;
    setOperationLocations(updatedLocations);
    debouncedUpdateUserRef.current({
      operationLocations: updatedLocations
    });
  };

  const handleAddOperationLocation = () => {
    setOperationLocations([...operationLocations, '']);
  };

  return (
    <>
      <H2>Website</H2>
      <div>
        {selectedCodes.map((code, index) => (
          <div key={index} className="form-group">
            <Label>{code.description}:</Label>
            <TextInput
              type="text"
              value={websites[code.key] || ''}
              onChange={(e) => handleChangeWebsite(code.key, e.target.value)}
            />
          </div>
        ))}
      </div>
      <H2>Scale of Operations</H2>
      <div>
        {selectedCodes.map((code, index) => (
          <div key={index} className="form-group">
            <Label>{code.description}:</Label>
            <select
              className="form-control"
              value={scales[code.key] || ''}
              onChange={(e) => handleChangeScale(code.key, e.target.value)}
            >
              <option value="">Select scale...</option>
              <option value="Small">Small: Less than 50 employees; Revenue under €10 million.</option>
              <option value="Medium">Medium: 50-250 employees; Revenue between €10 million and €50 million.</option>
              <option value="Large">Large: More than 250 employees; Revenue over €50 million.</option>
            </select>
          </div>
        ))}
      </div>
      <H2>Location of Headquarters</H2>
      <TextInput
        type="text"
        value={headquarterLocation}
        onChange={handleChangeHeadquarterLocation}
      />

      <H2>Location of Operations</H2>
      <div>
        {operationLocations.map((location, index) => (
          <div key={index} className="form-group">
            <Label>Location {index + 1}:</Label>
            <TextInput
              type="text"
              value={location}
              onChange={(e) => handleChangeOperationLocation(index, e.target.value)}
            />
          </div>
        ))}
      </div>
      <ButtonPrimary onClick={handleAddOperationLocation}>Add Location</ButtonPrimary>
      <H2>Sustainability practices</H2>
      <div style={{textAlign: 'left'}}>
        <h4>Certifications and Standards:</h4>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.certificationsAndStandards?.includes('ISO 14001')}
            onChange={(e) => handleCheckboxChange('certificationsAndStandards', 'ISO 14001')}
          />
          ISO 14001
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.certificationsAndStandards?.includes('B Corp')}
            onChange={(e) => handleCheckboxChange('certificationsAndStandards', 'B Corp')}
          />
          B Corp
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.certificationsAndStandards?.includes('Fair Trade')}
            onChange={(e) => handleCheckboxChange('certificationsAndStandards', 'Fair Trade')}
          />
          Fair Trade
        </label>
      </div>
      <div>
        <h4>Sustainability Reporting:</h4>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.sustainabilityReporting?.includes('Following GRI')}
            onChange={(e) => handleCheckboxChange('sustainabilityReporting', 'Following GRI')}
          />
          Following GRI
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.sustainabilityReporting?.includes('SDG')}
            onChange={(e) => handleCheckboxChange('sustainabilityReporting', 'SDG')}
          />
          SDG
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.sustainabilityReporting?.includes('CDP')}
            onChange={(e) => handleCheckboxChange('sustainabilityReporting', 'CDP')}
          />
          CDP
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.sustainabilityReporting?.includes('SASB')}
            onChange={(e) => handleCheckboxChange('sustainabilityReporting', 'SASB')}
          />
          SASB
        </label>
      </div>
      <div>
        <h4>Environmental Practices:</h4>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.environmentalPractices?.includes('Energy efficiency')}
            onChange={(e) => handleCheckboxChange('environmentalPractices', 'Energy efficiency')}
          />
          Energy efficiency
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.environmentalPractices?.includes('Waste reduction')}
            onChange={(e) => handleCheckboxChange('environmentalPractices', 'Waste reduction')}
          />
          Waste reduction
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.environmentalPractices?.includes('Renewable energy use')}
            onChange={(e) => handleCheckboxChange('environmentalPractices', 'Renewable energy use')}
          />
          Renewable energy use
        </label>
      </div>
      <div>
        <h4>Social Commitments:</h4>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.socialCommitments?.includes('Labor practices')}
            onChange={(e) => handleCheckboxChange('socialCommitments', 'Labor practices')}
          />
          Labor practices
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.socialCommitments?.includes('Community engagement')}
            onChange={(e) => handleCheckboxChange('socialCommitments', 'Community engagement')}
          />
          Community engagement
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.socialCommitments?.includes('Diversity and inclusion policies')}
            onChange={(e) => handleCheckboxChange('socialCommitments', 'Diversity and inclusion policies')}
          />
          Diversity and inclusion policies
        </label>
      </div>
      <div>
        <h4>Governance:</h4>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.governance?.includes('Ethical business practices')}
            onChange={(e) => handleCheckboxChange('governance', 'Ethical business practices')}
          />
          Ethical business practices
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.governance?.includes('Transparency')}
            onChange={(e) => handleCheckboxChange('governance', 'Transparency')}
          />
          Transparency
        </label>
        <label>
          <input
            type="checkbox"
            checked={checkboxes.governance?.includes('Anti-corruption measures')}
            onChange={(e) => handleCheckboxChange('governance', 'Anti-corruption measures')}
          />
          Anti-corruption measures
        </label>
      </div>
    </>
  )
}

export default CompanyInformation;