import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchNaceCodes = createAsyncThunk(
  'naceCodes/fetchNaceCodes',
  async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + `/nace/codes`);
    return await response.json();
  }
);

const naceCodesSlice = createSlice({
  name: 'naceCodes',
  initialState: {
    naceCodes: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchNaceCodes.pending]: (state) => {
      state.loading = true;
    },
    [fetchNaceCodes.fulfilled]: (state, action) => {
      state.naceCodes = action.payload;
      state.loading = false;
    },
    [fetchNaceCodes.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default naceCodesSlice.reducer;