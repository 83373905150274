import styled from "styled-components";

export const CenteredContainer = styled.div`
  text-align: center;
`;

export const TextInput = styled.input`
  width: 200px;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 2rem;
  border: 1px solid ${({ theme }) => theme.colorGray500};
  border-radius: ${({ theme }) => theme.mainRadius};
`;

export const Label = styled.label`
    margin-right: 10px;
`;