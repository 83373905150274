import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableData,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from "./styled";
import {getSustainabilityMatterTopic} from "./index";


const ImpactMaterialityTable = ({ doubleMateriality }) => {
  return (
    <TableContainer key={'table-impact-materiality'}>
      <Table>
        {doubleMateriality.impact_materiality
          .filter(item => item && item.sustainability_matter)
          .map((item, index) => (
            <>
              <TableHeader>
                <TableHeaderRow key={'header-'+index}>
                  <TableHeaderCell>{getSustainabilityMatterTopic(item.sustainability_matter)}</TableHeaderCell>
                  <TableHeaderCell colSpan={2}>{item.sustainability_matter?.reason}</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                <TableRow key={'likelihood-'+index}>
                  <TableHeaderCell>Impact Likelihood Score</TableHeaderCell>
                  <TableData>{item.impact_likelihood_score}</TableData>
                  <TableData colSpan={2}>{item.impact_likelihood_reason}</TableData>
                </TableRow>
                <TableRow key={'severity-'+index}>
                  <TableHeaderCell>Impact Severity Score</TableHeaderCell>
                  <TableData>{item.severity_score}</TableData>
                  <TableData>{item.severity_reason}</TableData>
                </TableRow>
                <TableRow key={'scope-'+index}>
                  <TableHeaderCell>Scope</TableHeaderCell>
                  <TableData></TableData>
                  <TableData>{item.scope}</TableData>
                </TableRow>
                <TableRow key={'scale-'+index}>
                  <TableHeaderCell>Scale</TableHeaderCell>
                  <TableData></TableData>
                  <TableData>{item.scale}</TableData>
                </TableRow>
                <TableRow key={'remediation-'+index}>
                  <TableHeaderCell>Remediation</TableHeaderCell>
                  <TableData></TableData>
                  <TableData>{item.irremediable_character}</TableData>
                </TableRow>
              </TableBody>
            </>
          ))}
      </Table>
    </TableContainer>
  );
}

export default ImpactMaterialityTable;