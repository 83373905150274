import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonWrapper, CenteredContainer } from "./styled";
import {ButtonPrimary, WrapperContent} from "../shared/GlobalComponents";
import { ErrorMessage, TextInput, TextInputWrapper, Title } from "../AnalysisForm/Step4/styled";
import { setEmail, setAccessToken, setError } from "../../store/reducers/userSlice";

function Login() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email, error } = useSelector((state) => state.user);
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      dispatch(setError(''));

      const response = await fetch(API_URL + `/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error;
        dispatch(setError("An error occurred: " + errorMessage));
        return;
      }

      const userData = await response.json();
      dispatch(setEmail(userData.email));
      dispatch(setAccessToken(userData.access_token));

      navigate("/results");
    } catch (err) {
      dispatch(setError("An error occurred: " + err.message));
    }
  };

  const handleSignup = () => {
    navigate("/analysis");
  };

  return (
    <WrapperContent>
      <CenteredContainer>
        <Title>Login to Your Account</Title>
        <TextInputWrapper>
          <TextInput
            type="email"
            placeholder="Company email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
          <TextInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </TextInputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonWrapper>
          <ButtonPrimary onClick={handleLogin}>Login</ButtonPrimary>
          <ButtonPrimary onClick={handleSignup}>Signup</ButtonPrimary>
        </ButtonWrapper>
      </CenteredContainer>
    </WrapperContent>
  );
}

export default Login;
