import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  text-align: center;
  display: inline-flex;
  align-items: center;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

const Text = styled.span`
  margin-left: 8px;
`;

const ProcessingSpinner = ({ text = "Processing…" }) => {
  return (
    <Container>
      <Spinner />
      <Text>{text}</Text>
    </Container>
  );
};

export default ProcessingSpinner;
