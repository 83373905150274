import React from 'react';
import {P, Table } from "../shared/GlobalComponents";
import {TableBody, TableData, TableHeader, TableHeaderCell, TableHeaderRow, TableRow} from "./styled";
import EmptyTableRow from './EmptyTableRow';

const SustainabilityMatters = ({ results }) => {
  if (!results || Object.keys(results).length === 0) {
    return <P>No results.</P>;
  }
  return (
    <>
      {Object.entries(results).sort().map(([naceCode, data]) => {
        let sustainabilityMatters = data.sustainability_matters;
        let displayedCode = naceCode ? naceCode.slice(1, -1) : '';
        let description = data.impacts_and_dependencies['nace_rev2_description'];
        return (
          <>
            <P>NACE {displayedCode}: {description}</P>
            <Table key={naceCode}>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Sustainability Matter</TableHeaderCell>
                  <TableHeaderCell>Reason</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {sustainabilityMatters.length > 0 ? (
                  sustainabilityMatters.map((item, index) => (
                    <TableRow key={index} isLastRow={index === sustainabilityMatters.length - 1}>
                      <TableData>{item.generatedtopic || item.subsubtopic || item.subtopic || item.topic}</TableData>
                      <TableData>{item.reason}</TableData>
                    </TableRow>
                  ))
                ) : (
                  <EmptyTableRow colSpan={2} isProcessing={data.processing}/>
                )}
              </TableBody>
            </Table>
          </>
        );
      })}
    </>
  );
};

export default SustainabilityMatters;