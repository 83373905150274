import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CenteredContainer, Highlighted} from "../styled";
import {ButtonPrimary, H1, P, WrapperContent} from "../../shared/GlobalComponents";
import ImpactsTable from "../ImpactsTable";
import {getImpactsAndDependencies} from "../../../store/reducers/analysisSlice";

import {useNavigate} from "react-router";

const Step3 = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImpactsAndDependencies());
  }, [dispatch])


  const analysisState = useSelector(state => state.analysis);

  if (!analysisState) {
    //return <div>Data is empty...</div>;
  }

  const handleButtonClick = () => {
    navigate("/analysis/4");
  }

  return (
    <WrapperContent>
      <CenteredContainer>
        <H1>Your likely <Highlighted>impacts and dependencies</Highlighted> are:</H1>
        <ImpactsTable
          key={'impacts'}
          results={analysisState.likelyImpacts}
          impactHeader={(<><Highlighted>Likely Impacts</Highlighted> to nature and people</>)}
        />
        <ImpactsTable
          key={'dependencies'}
          results={analysisState.likelyDependencies}
          impactHeader={(<><Highlighted>Likely Dependencies</Highlighted> on Ecosystem Services </>)}
        />
        <P>See the materiality ratings and indicators by creating a free account</P>
        <ButtonPrimary onClick={handleButtonClick}>Create a FREE account</ButtonPrimary>
      </CenteredContainer>
    </WrapperContent>
  );
};

export default Step3;