import React from 'react';
import {Spinner, SpinnerOverlay} from "./styled";
import SpinnerAnimation from "./SpinnerAnimation";

const LoadingSpinner = () => {
  return (
    <SpinnerOverlay>
      <Spinner>
        <SpinnerAnimation />
        Loading...
      </Spinner>
    </SpinnerOverlay>
  );
};

export default LoadingSpinner;