import styled, {css} from 'styled-components';

export const TableContainer = styled.div`
  margin-bottom: 2rem;
  padding: .25rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.colorGray100};
`;

export const TableHeader = styled.thead`
  padding: .5rem;
  background-color: ${({ theme }) => theme.colorGray100};
`;

export const TableHeaderRow = styled.tr``;

export const TableHeaderCell = styled.th`
  padding: .5rem;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  border: 1px solid ${({ theme }) => theme.colorGray100};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  ${({ isLastRow }) => isLastRow && css`
    border-bottom: px solid ${({ theme }) => theme.colorGray100};
  `}
`;

export const TableData = styled.td`
  border: 1px solid ${({ theme }) => theme.colorGray100};
  padding: .5rem;
`;
