import styled from "styled-components";

export const CheckboxContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const AddButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%
`;

export const CheckboxLabel = styled.div`
  margin-left: 10px;
`;