import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableData,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from "./styled";
import {getSustainabilityMatterTopic} from "./index";

const FinancialMaterialityTable = ({ doubleMateriality }) => {
  return (
    <TableContainer key={'table-financial-materiality'}>
      <Table>
        {doubleMateriality.financial_materiality
          .filter(item => item && item.sustainability_matter)
          .map((item, index) => (
            <>
              <TableHeader>
                <TableHeaderRow key={'header-'+index}>
                  <TableHeaderCell>{getSustainabilityMatterTopic(item.sustainability_matter)}</TableHeaderCell>
                  <TableHeaderCell colSpan={2}>{item.sustainability_matter?.reason}</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                <TableRow key={'likelihood-'+index}>
                  <TableHeaderCell>Risk Likelihood Score</TableHeaderCell>
                  <TableData>{item.risk_likelihood_score}</TableData>
                  <TableData colSpan={2}>{item.risk_likelihood_reason}</TableData>
                </TableRow>
                <TableRow key={'magnitude-'+index}>
                  <TableHeaderCell>Risk Magnitude Score</TableHeaderCell>
                  <TableData>{item.risk_magnitude_score}</TableData>
                  <TableData>{item.risk_magnitude_reason}</TableData>
                </TableRow>
              </TableBody>
            </>
          ))}
      </Table>
    </TableContainer>
  );
}

export default FinancialMaterialityTable;