import React from "react";
import {Body, Cell, Header, HeaderCell, HeaderRow, Row, Table} from "../ImpactsTable/styled";

const ESRTable = ({results}) => {
  return (<Table>
    <Header>
      <HeaderRow>
        <HeaderCell>ESRS Sustainability Topics</HeaderCell>
      </HeaderRow>
    </Header>
    <Body>
      {results.map(result => (
        <Row key={'ers-' + result}>
          <Cell>{result}</Cell>
        </Row>))
      }
    </Body>
  </Table>)

}

export default ESRTable;