import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setFirstName, setEmail, setError, logout } from '../../store/reducers/userSlice';
import {CenteredContainer, Label, TextInput} from './styled';
import {ButtonPrimary, H1, WrapperContent} from "../shared/GlobalComponents";

function Account() {
  const API_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { firstName, email, accessToken, error } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(API_URL + `/user-details`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.error;
          dispatch(setError("An error occurred: " + errorMessage));
          return;
        }

        const userData = await response.json();
        dispatch(setFirstName(userData.first_name));
        dispatch(setEmail(userData.email));

      } catch (err) {
        dispatch(setError("An error occurred: " + err.message));
      }
    };

    fetchUserData();
  }, [API_URL, dispatch, accessToken]);

  const handleUpdateClick = async () => {
    try {
      const response = await fetch(API_URL + `/update-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({firstName, email}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error;
        dispatch(setError("An error occurred: " + errorMessage));
        return;
      }

      alert("User details updated successfully!");
      dispatch(setError(""));
    } catch (err) {
      dispatch(setError("An error occurred: " + err.message));
    }
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <WrapperContent>
      <CenteredContainer>
        <H1>Account Details</H1>
        <div>
          <Label>Name:</Label>
          <TextInput
            type="text"
            value={firstName}
            onChange={(e) => dispatch(setFirstName(e.target.value))}
          />
        </div>
        <div>
          <Label>Email:</Label>
          <TextInput
            type="email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
        </div>
        {error && <p style={{color: 'red'}}>{error}</p>}
        <ButtonPrimary onClick={handleUpdateClick}>Update</ButtonPrimary>
        <ButtonPrimary onClick={handleLogoutClick}>Logout</ButtonPrimary>
      </CenteredContainer>
    </WrapperContent>
  );
}

export default Account;