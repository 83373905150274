import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchCnCodes = createAsyncThunk(
  'cnCodes/fetchNaceCodes',
  async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + `/cn/codes`);
    return await response.json();
  }
);

const cnCodesSlice = createSlice({
  name: 'cnCodes',
  initialState: {
    cnCodes: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchCnCodes.pending]: (state) => {
      state.loading = true;
    },
    [fetchCnCodes.fulfilled]: (state, action) => {
      state.cnCodes = action.payload;
      state.loading = false;
    },
    [fetchCnCodes.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default cnCodesSlice.reducer;