import React from 'react';
import {ButtonPrimary} from "../../../shared/GlobalComponents";

const AddButton = ({ onPress }) => {

  const handleButtonClick = () => {
    onPress("Your Argument Here");
  };

  return (
    <ButtonPrimary onClick={handleButtonClick}>
      <span className="icon">+</span> Add economic activity, product, or service
    </ButtonPrimary>
  );
};

export default AddButton;