import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import RequestStatus from "../../constants/RequestStatus";

const initialState = {
  firstName: '',
  email: '',
  naceCode: '',
  isLoggedIn: false,
  accessToken: '',
  error: null,
  headquarterLocation: '',
  groupCodes: [],
  downstreamCodes: [],
  upstreamCodes: [],
  groupProdcomCodes: [],
  groupCnCodes: [],
  websites: [],
  scales: [],
  operationLocations: [],
  sustainabilityPractices: [],
  updateUserRequestStatus: RequestStatus.Idle,
  getUserRequestStatus: RequestStatus.Idle,
};

export const getUser = createAsyncThunk('users/getUser', async (_, thunkAPI) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const accessToken = localStorage.getItem('access_token');

  try {
    const response = await fetch(`${API_URL}/user-details`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      return thunkAPI.rejectWithValue(errorData);
    }

    const userData = await response.json();

    const {
      email,
      firstName,
      group_nace_codes,
      downstream_nace_codes,
      upstream_nace_codes,
      nace_code,
      group_prodcom_codes,
      group_cn_codes,
      headquarter_location,
      websites,
      scales,
      operation_locations,
      sustainability_practices
    } = userData;

    return thunkAPI.fulfillWithValue({
      email,
      firstName,
      groupCodes: group_nace_codes,
      downstreamCodes: downstream_nace_codes,
      upstreamCodes: upstream_nace_codes,
      naceCode: nace_code,
      group_prodcom_codes: group_prodcom_codes,
      group_cn_codes: group_cn_codes,
      headquarterLocation: headquarter_location,
      websites: websites,
      scales: scales,
      operationLocations: operation_locations,
      sustainabilityPractices: sustainability_practices
    });

  } catch (error) {
    return thunkAPI.rejectWithValue({error: 'An error occurred while fetching user details.'});
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async (data, thunkAPI) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const accessToken = localStorage.getItem('access_token');
console.log(data, JSON.stringify(data));
  try {
    const response = await fetch(`${API_URL}/update-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`, // Add the Authorization header
      },
      body: JSON.stringify(data),
    });
    const userData = await response.json();
    return userData;
  } catch (error) {
    return thunkAPI.rejectWithValue({error: 'An error occurred while updating the user.'});
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    checkLoginStatus: (state) => {
      const token = localStorage.getItem('access_token');

      if (token) {
        const decodedToken = jwt_decode(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp >= currentTimestamp) {
          state.isLoggedIn = true;
          state.accessToken = token
        } else {
          localStorage.removeItem('access_token');
          state.isLoggedIn = false;
          state.accessToken = '';
        }
      }
    },
    setAccessToken: (state, action) => {
      const token = action.payload;
      if (token) {
        const decodedToken = jwt_decode(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp >= currentTimestamp) {
          localStorage.setItem('access_token', token);
          state.accessToken = token
          state.isLoggedIn = true;
        }
      }
    },
    logout: () => {
      localStorage.removeItem('access_token');
      return initialState
    },
    setCodes: (state, action) => {
      state[action.payload.type + 'Codes'] = action.payload.codes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.getUserRequestStatus = RequestStatus.Loading;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.getUserRequestStatus = RequestStatus.Success;
        state.email = action.payload.email;
        state.firstName = action.payload.firstName;
        state.groupCodes = action.payload.groupCodes;
        state.downstreamCodes = action.payload.downstreamCodes;
        state.upstreamCodes = action.payload.upstreamCodes;
        state.naceCode = action.payload.naceCode;
        state.groupProdcomCodes = action.payload.group_prodcom_codes;
        state.groupCnCodes = action.payload.group_cn_codes;
        state.websites = action.payload.websites;
        state.scales = action.payload.scales;
        state.operationLocations = action.payload.operationLocations;
        state.headquarterLocation = action.payload.headquarterLocation;
        state.sustainabilityPractices = action.payload.sustainabilityPractices;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.getUserRequestStatus = RequestStatus.Failed;
      })

      .addCase(updateUser.pending, (state) => {
        state.updateUserRequestStatus = RequestStatus.Loading;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserRequestStatus = RequestStatus.Success;
        state.email = action.payload.user.email;
        state.firstName = action.payload.user.firstName;
        state.groupCodes = action.payload.user.group_nace_codes;
        state.downstreamCodes = action.payload.user.downstream_nace_codes;
        state.upstreamCodes = action.payload.user.upstream_nace_codes;
        state.naceCode = action.payload.user.group_nace_codes;
        state.groupProdcomCodes = action.payload.user.group_prodcom_codes;
        state.groupCnCodes = action.payload.user.group_cn_codes;
        state.headquarterLocation = action.payload.user.headquarter_location;
        state.websites = action.payload.user.websites;
        state.scales = action.payload.user.scales;
        state.operationLocations = action.payload.user.operation_locations;
        state.sustainabilityPractices = action.payload.user.sustainability_practices;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserRequestStatus = RequestStatus.Failed;
        state.error = action.payload;
      })
  },
});

export const {
  setFirstName,
  setEmail,
  setError,
  checkLoginStatus,
  setAccessToken,
  logout,
  setCodes
} = userSlice.actions;

export default userSlice.reducer;