import { Route, Navigate } from "react-router-dom";

export function createAnalysisRoute(path, element, isLoggedIn) {
  return (
    <Route
      key={path}
      path={path}
      element={isLoggedIn ? <Navigate to="/assessment" replace /> : element}
    />
  );
}