import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appInitialized: (state) => {
      state.loading = false;
    },
  },
});

export const { appInitialized } = appSlice.actions;
export default appSlice.reducer;
