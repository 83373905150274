import { createGlobalStyle } from 'styled-components';

// Base styles to apply globally
const GlobalStyle = createGlobalStyle`

  body {
    font-family: "Inter", system-ui, sans-serif;
    color: ${({ theme }) => theme.colorTextMain};
    margin: 0;
    padding: 0;
  }

  input, select {
    font-size: 14px;
  }

  table {
    th,
    td {
      font-size: 14px;
    }
  }

  .wrapper-logo {
    position: relative; 
    top: 2px;
    display: inline-block;
  }

  .text-center {
    text-align: center;
  }

`;

export default GlobalStyle;
