import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Input, DropdownList, DropdownItem } from './styled';

const CodeDropdownSearch = ({onSelect, value=null, codes='nace'}) => {

    const codeList = useSelector(state => {
        if (codes === 'nace') {
            return state.naceCodes.naceCodes;
        } else if (codes === 'prodcom') {
            return state.prodcomCodes.prodcomCodes;
        } else if (codes === 'cn') {
            return state.cnCodes.cnCodes;
        }
    });

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCode, setSelectedCode] = useState(null);
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    useEffect(() => {

        if ( codeList.length < 1 || !value ) {
            return;
        }

        const valueObject = codeList.find((element) => element.key === value);

        setSelectedCode(valueObject);
        setIsItemSelected(true);

    }, [value, codeList]);

    const handleItemClick = (code) => {
        setSelectedCode(code);
        setIsItemSelected(true);
        setIsDropdownVisible(false);
        onSelect(code);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsItemSelected(false);
    };

    const filteredNaceCodeList = searchTerm
        ? codeList.filter(code => code.description.toLowerCase().includes(searchTerm.toLowerCase())
            || code.display.toLowerCase().includes(searchTerm.toLowerCase()))
        : codeList;

    return (
        <Container>
            <Input
                type="text"
                placeholder="Search..."
                value={isItemSelected ? `${selectedCode?.display} - ${selectedCode?.description}` : searchTerm}
                onChange={handleInputChange}
                onFocus={() => {
                    setIsDropdownVisible(true);
                    if(isItemSelected) {
                        setSearchTerm('');
                        setIsItemSelected(false);
                    }
                }}
                onBlur={() => {
                    setTimeout(() => setIsDropdownVisible(false), 200);
                }}
            />
            {!isItemSelected && isDropdownVisible && (
                <DropdownList>
                    {filteredNaceCodeList.map(code => (
                        <DropdownItem key={code.key} onClick={() => handleItemClick(code)}>
                            {`${code.display} - ${code.description}`}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </Container>
    );
}

export default CodeDropdownSearch;