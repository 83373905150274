import {MaterialityBadge, P} from "../shared/GlobalComponents";
import {Table, TableBody, TableData, TableHeader, TableHeaderCell, TableHeaderRow, TableRow} from "./styled";
import React from "react";
import EmptyTableRow from "./EmptyTableRow";


const ImpactsAndMateriality = ({ results }) => {
  if (!results || Object.keys(results).length === 0) {
    return <P>No results.</P>;
  }
  return (
    <>
      {Object.entries(results).sort().map(([naceCode, data]) => {
        let impacts = data.processing ? data.impacts_and_dependencies['impacts'] : data.impacts_risks_opportunities['impacts']
        let displayedCode = naceCode ? naceCode.slice(1, -1) : '';
        let description = data.impacts_and_dependencies['nace_rev2_description'];
        return (
          <>
            <P>NACE {displayedCode}: {description}</P>
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Likely Impacts to nature and people</TableHeaderCell>
                  <TableHeaderCell>Likely Materiality</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {impacts.length > 0 ? (
                  impacts.map((item, index) => (
                    <TableRow key={index} isLastRow={index === impacts.length - 1}>
                      <TableData>
                        {item.impact || item.process}
                      </TableData>
                      <TableData>
                        <MaterialityBadge materiality={item.materiality}>
                          {item.materiality}
                        </MaterialityBadge>
                      </TableData>
                    </TableRow>
                  ))
                ) : <EmptyTableRow colSpan={2} isProcessing={data.processing}/> }
              </TableBody>
            </Table>
          </>
        );
      })}
    </>
  );
}

export default ImpactsAndMateriality