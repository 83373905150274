import React from "react";
import {TableData, TableRow} from "./styled";
import ProcessingSpinner from "../shared/ProcessingSpinner";

const EmptyTableRow = ({ colSpan, isProcessing }) => {
  return (
    <TableRow>
      <TableData colSpan={colSpan} style={{textAlign: 'center'}}>
        {isProcessing ? <ProcessingSpinner text={"Processing... (This could take some minutes)"}/> : ""}
      </TableData>
    </TableRow>
  );
};

export default EmptyTableRow;