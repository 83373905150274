import {ButtonPrimary, H1, WrapperContent, P} from "../shared/GlobalComponents";
import {useNavigate} from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  function handleStart() {
    navigate('/analysis')
  }

  function handleLogin() {
    navigate('/login')
  }

  return (
    <WrapperContent>
        <H1 className="text-center">Your Quick Start to Sustainability Success & CSRD Compliance</H1>
        <P className="text-center">
          <strong>5 min from start-to-finish!</strong>
        </P>
        <ul>
          <li>Double Materiality Assessment Demystified</li>
          <li>Sensitive company data not required</li>
          <li>Prior expertise not required</li>
        </ul>
        <div className="text-center">
          <ButtonPrimary onClick={handleStart} className="w-25">Start</ButtonPrimary>
          <ButtonPrimary onClick={handleLogin} className="w-25">or Login</ButtonPrimary>
        </div>
    </WrapperContent>
  );
};

export default Landing;