import styled from 'styled-components';

export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Spinner = styled.div`
  font-size: 3rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;