import React from "react";
import {Blurred, Body, Cell, Header, HeaderCell, HeaderRow, Row, Table} from "./styled";

const ImpactsTable = ({ results, impactHeader}) => {
  return (<Table>
    <Header>
      <HeaderRow>
        <HeaderCell>{impactHeader}</HeaderCell>
        <HeaderCell><strong>Likely Materiality</strong></HeaderCell>
      </HeaderRow>
    </Header>
    <Body>
      {results.map((result, index) => (result?.materiality !== '-') ? (
        <Row key={index}>
          <Cell>{result.process}</Cell>
          <Cell>{result.materiality}</Cell>
        </Row>
      ) : (
        <Blurred key={index}>
          <Cell>{result.process}</Cell>
          <Cell>{result.materiality}</Cell>
        </Blurred>
      ))}
    </Body>
  </Table>)

}

export default ImpactsTable;