import { useEffect, useRef, useCallback } from 'react';

const useIntervalFetch = (dispatch, action, shouldRefreshFunc, refreshInterval) => {
  const DEBUG_LOGS = false;
  const intervalRef = useRef(null);

  // Clears the interval
  const clearFetchInterval = useCallback(() => {
    if (intervalRef.current) {
      if (DEBUG_LOGS) { console.log('Clearing interval'); }
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [DEBUG_LOGS]);

  // Check and fetch data
  const checkAndFetch = useCallback(() => {
    if (shouldRefreshFunc()) {
      if (DEBUG_LOGS) { console.log('Dispatching action'); }
      dispatch(action());
    } else {
      clearFetchInterval();
    }
  }, [dispatch, action, shouldRefreshFunc, clearFetchInterval, DEBUG_LOGS]);

  // Set up the interval
  useEffect(() => {
    // Clear any existing interval when the hook is re-initialized
    clearFetchInterval();

    if (shouldRefreshFunc()) {
      if (DEBUG_LOGS) { console.log('Setting up interval'); }
      intervalRef.current = setInterval(checkAndFetch, refreshInterval);
    }

    // Clear the interval when the component unmounts
    return clearFetchInterval;
  }, [checkAndFetch, shouldRefreshFunc, refreshInterval, clearFetchInterval, DEBUG_LOGS]);

  // Initial fetch
  useEffect(() => {
    if (DEBUG_LOGS) { console.log('Initial fetch'); }
    dispatch(action());
  }, [dispatch, action, DEBUG_LOGS]);
};

export default useIntervalFetch;