import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import RequestStatus from "../../constants/RequestStatus";

const API_URL = process.env.REACT_APP_API_URL

const initialState = {
  nace_code: '',
  country: '',
  isGroup: false,
  groupCodes: [],
  downstreamCodes: [],
  upstreamCodes: [],
  likelyImpacts: [],
  likelyDependencies: [],
  sustainabilityTopics: [],
  getImpactsAndDependenciesRequestStatus: RequestStatus.Idle,
};

export const getImpactsAndDependencies = createAsyncThunk(
  'getImpactsAndDependencies',
  async (args, { getState }) => {
    const state = getState();
    const code = args?.naceCode ?? state.analysis.nace_code;

    let API_ENDPOINT = API_URL + `/form/impactsAndDependencies?nace_code=${code}`;

    const token  = localStorage.getItem('access_token');
    const headers = token != null ? {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    } : {};

    try {
      const response = await fetch(API_ENDPOINT, headers);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
);

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setNaceCode: (state, action) => {
      const {nace, isGroup} = action.payload;
      state.nace_code = nace;
      state.isGroup = isGroup;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setNaceAnalysisData: (state, action) => {
      state.naceAnalysisData = action.payload;
    },
    setGroupCodes: (state, action) => {
      state.groupCodes = action.payload;
    },
    setCodes: (state, action) => {
      state[action.payload.type + 'Codes'] = action.payload.codes;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getImpactsAndDependencies.pending, (state) => {
        state.getImpactsAndDependenciesRequestStatus = RequestStatus.Loading;
      })
      .addCase(getImpactsAndDependencies.fulfilled, (state, action) => {
        state.likelyImpacts = action?.payload?.impacts ?? [];
        state.likelyDependencies = action?.payload?.dependencies ?? [];
        state.sustainabilityTopics = action?.payload?.topics ?? [];
        state.getImpactsAndDependenciesRequestStatus = RequestStatus.Success;
      })
      .addCase(getImpactsAndDependencies.rejected, (state) => {
        state.getImpactsAndDependenciesRequestStatus = RequestStatus.Failed;
      })
  },
});

export const { setNaceCode, setCountry , setNaceAnalysisData, setGroupCodes, setCodes } = analysisSlice.actions;
export default analysisSlice.reducer;