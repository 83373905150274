import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import './App.css';
import './custom-bootstrap.scss';
import store from "./store/Store";
import AppContent from "./AppContent";
import {ThemeProvider} from "styled-components";
import GlobalStyle from "./components/shared/GlobalStyle";
import GlobalTheme from './components/shared/GlobalTheme';


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={GlobalTheme}>
          <GlobalStyle />
          <AppContent/>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
