import styled from "styled-components";

export const Highlighted = styled.span`
  // TODO: Delete individual styles, we use globl styling for that
`;

export const CenteredContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;