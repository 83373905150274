import React, {useEffect} from "react";
import {H1, WrapperContent} from "../shared/GlobalComponents";
import OperationGroup from "./OperationGroup";
import {useDispatch} from "react-redux";
import {getUser} from "../../store/reducers/userSlice";
import {TABS} from '../../constants/tabConstants';
import CompanyInformation from "./CompanyInformation";

function CompanyProfile() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  },[dispatch]);

  return (
    <>
      <WrapperContent>
        <H1>Company Information</H1>
        <OperationGroup group={TABS.GROUP}/>
        <OperationGroup group={TABS.UPSTREAM}/>
        <OperationGroup group={TABS.DOWNSTREAM}/>
        <CompanyInformation/>
      </WrapperContent>
    </>
  );

}

export default CompanyProfile;