import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {CenteredContainer} from '../styled'
import CodeDropdownSearch from "../../NACEAssessment/CodeDropdownSearch";
import {setGroupCodes} from '../../../store/reducers/analysisSlice';
import {AddButtonContainer} from "./styled";
import {Body, Cell, Row, Table} from "../ImpactsTable/styled";
import AddButton from "./AddButton";
import {ButtonPrimary, H1, WrapperContent} from "../../shared/GlobalComponents";

const GroupNace = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [naceCodes, setNaceCodes] = useState([]);

  const analysisState = useSelector(state => state.analysis);

  useEffect(() => {
    setNaceCodes([analysisState.nace_code]);
  }, [analysisState.nace_code]);

  const handleSelect = (key, value) => {

    const codes = [...naceCodes];
    codes[key] = value.key;

    setNaceCodes(codes);

  };

  const handleNext = () => {

    dispatch(setGroupCodes(naceCodes));
    navigate("/analysis/2");

  }

  const onAddPress = () => {

    const codes = [...naceCodes];
    codes.push(null);

    setNaceCodes(codes);

  }

  return (
    <WrapperContent>
      <CenteredContainer>
        <H1>What else does your group do?</H1>
        <Table>
          <Body>
            {
              naceCodes.map((code, index) => {
                let displayedCode = code ? code.slice(1, -1) : '';
                return (
                  <Row key={'nace-' + index}>
                    <Cell>
                      <CodeDropdownSearch
                        value={code}
                        onSelect={value => {
                          handleSelect(index, value)
                        }}
                      />
                    </Cell>
                    <Cell>
                      NACE 2.0 {displayedCode}
                    </Cell>
                  </Row>
                )
              })
            }
          </Body>
        </Table>

        <AddButtonContainer>
          <AddButton onPress={onAddPress}/>
        </AddButtonContainer>

        <ButtonPrimary type="button"
                value="submit "
                onClick={handleNext}
                disabled={naceCodes.length < 1}>Next</ButtonPrimary>
      </CenteredContainer>
    </WrapperContent>
  )
}

export default GroupNace;