import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {appInitialized} from './store/reducers/appSlice';
import {checkLoginStatus} from "./store/reducers/userSlice";
import {fetchNaceCodes} from "./store/reducers/naceCodesSlice";
import {fetchProdcomCodes} from "./store/reducers/prodcomCodesSlice";
import NavBar from "./components/NavBar";
import Step1 from "./components/AnalysisForm/Step1";
import Step2 from "./components/AnalysisForm/Step2";
import Step3 from "./components/AnalysisForm/Step3";
import Step4 from "./components/AnalysisForm/Step4";
import Step5 from "./components/AnalysisForm/Step5";
import Login from "./components/Login";
import {createAnalysisRoute} from "./utils/analysisRoute";
import {createPrivateRoute} from "./utils/privateRoute";
import CompanyProfile from "./components/CompanyProfile";
import Account from "./components/Account";
import GroupNace from "./components/AnalysisForm/Step1/GroupNace";
import Landing from "./components/Landing";
import {fetchCnCodes} from "./store/reducers/cnCodesSlice";
import AssessmentV2 from "./components/AssessmentV2";

function AppContent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const showNavBar = !location.pathname.startsWith("/analysis");

  useEffect(() => {
    dispatch(checkLoginStatus());
    dispatch(appInitialized());
    dispatch(fetchNaceCodes());
    dispatch(fetchProdcomCodes());
    dispatch(fetchCnCodes());
  }, [dispatch]);

  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isAppLoading = useSelector(state => state.app.loading);

  if (isAppLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {showNavBar && <NavBar />}
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to="/assessment" replace /> : <Landing/>} />
        {createAnalysisRoute("/analysis", <Step1/>, isLoggedIn)}
        {createAnalysisRoute("/analysis/1-1", <GroupNace/>, isLoggedIn)}
        {createAnalysisRoute("/analysis/2", <Step2/>, isLoggedIn)}
        {createAnalysisRoute("/analysis/3", <Step3/>, isLoggedIn)}
        {createAnalysisRoute("/analysis/4", <Step4/>, isLoggedIn)}
        {createAnalysisRoute("/analysis/5", <Step5/>, isLoggedIn)}
        {createAnalysisRoute("/login", <Login />, isLoggedIn)}
        {createPrivateRoute("/assessment", <AssessmentV2 />, isLoggedIn)}
        {createPrivateRoute("/profile", <CompanyProfile />, isLoggedIn)}
        {createPrivateRoute("/account",<Account />, isLoggedIn)}
      </Routes>
    </div>
  );
}

export default AppContent;