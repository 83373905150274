import styled from 'styled-components';

export const Title = styled.h1`

`;

export const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const TextInput = styled.input`
  width: 200px;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colorGray500};
  border-radius: ${({ theme }) => theme.mainRadius};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colorGray100};
  margin-bottom: 8px;
`;