import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchProdcomCodes = createAsyncThunk(
  'prodcomCodes/fetchNaceCodes',
  async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + `/prodcom/codes`);
    return await response.json();
  }
);

const prodcomCodesSlice = createSlice({
  name: 'prodcomCodes',
  initialState: {
    prodcomCodes: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchProdcomCodes.pending]: (state) => {
      state.loading = true;
    },
    [fetchProdcomCodes.fulfilled]: (state, action) => {
      state.prodcomCodes = action.payload;
      state.loading = false;
    },
    [fetchProdcomCodes.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default prodcomCodesSlice.reducer;