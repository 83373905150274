import { configureStore } from '@reduxjs/toolkit';
import assessmentReducer from './reducers/assessmentSlice';
import analysisReducer from './reducers/analysisSlice';
import appReducer from './reducers/appSlice';
import naceCodesReducer from './reducers/naceCodesSlice';
import prodcomCodesReducer from './reducers/prodcomCodesSlice';
import cnCodesReducer from './reducers/cnCodesSlice';
import userReducer from './reducers/userSlice';

const rootReducer = {
  assessment: assessmentReducer,
  analysis: analysisReducer,
  app: appReducer,
  naceCodes: naceCodesReducer,
  prodcomCodes: prodcomCodesReducer,
  cnCodes: cnCodesReducer,
  user: userReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;