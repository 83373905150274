// Theme-specific styles accessible in styled components

const GlobalTheme = {
  colorPrimary: 'hsl(69, 100%, 47%)',
  colorBackground: 'hsl(26, 47%, 97%)',

  colorWhite: 'hsl(26, 47%, 97%)',
  colorGray100: 'hsl(78, 9%, 90%)',
  colorGray500: 'hsl(78, 10%, 52%)',
  colorGray800: 'hsl(78, 10%, 26%)',
  colorGray900: 'hsl(78, 10%, 16%)',
  colorBlack: 'hsl(78, 71%, 3%)',

  colorTextMain: 'hsl(78, 71%, 3%)', // Directly use the value instead of var(--color-black)
  colorTextMuted: 'hsl(78, 10%, 26%)', // Directly use the value instead of var(--color-gray-800)
  colorDanger: 'hsl(11, 92%, 40%)',

  fontWeightLight: 400,
  fontWeightBase: 470,
  fontWeightBold: 550,
  fontWeightBolder: 750,
  contentWidthLimit: '42rem',
  radiusMain: '2px',
};

export default GlobalTheme;
