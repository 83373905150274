import styled from "styled-components";
import {lowerCase} from "lodash";

export const H1 = styled.h1`
  margin: 0 0 3rem 0;
  padding-top: 2rem;
  font-size: 36px;
  line-height: 36px;
  font-weight: ${({ theme }) => theme.fontWeightBolder};
`;

export const H2 = styled.h2`
  margin: 5rem 0 1rem 0;
  font-size: 24px;
  line-height: 36px;
  font-weight: ${({ theme }) => theme.fontWeightBolder};
  border-bottom: 2px solid ${({ theme }) => theme.colorPrimary};
`;

export const H3 = styled.h3`
  margin: 3rem 0 1rem 0;
  font-size: 21px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeightBolder};
`;

export const H4 = styled.h4`
  margin: 3rem 0 1rem 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeightBolder};
`;

export const P = styled.p`
  margin: 1rem 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.fontWeightBase};
`;

export const WrapperContent = styled.div`
  margin: 0 auto;
  padding: 0 3rem 3rem 3rem;
  max-width: ${({ theme }) => theme.contentWidthLimit};
  background-color: white; //  ${({ theme }) => theme.colorWhite};
`;

export const Table = styled.table`
  margin-bottom: 2rem;
  td, 
  th {
    font-size: 14px !important;
  }
`;

export const ButtonPrimary = styled.button`
  display: inline-block; 
  margin: .5rem;
  padding: 0.5rem 1.5rem;
  color: ${({ theme }) => theme.colorTextMain};
  background-color: ${({ theme }) => theme.colorPrimary};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeightBase};
  border-radius: ${({ theme }) => theme.radiusMain};
  border: none;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    outline: 2px solid ${({ theme }) => theme.colorTextMain};
  }

  &:disabled {
    opacity: 0.8;
  }
`;

export const MaterialityBadge = styled.span`
  padding: 2px 16px;
  border-radius: 999rem;
  font-size: 12px;
  background-color: ${({ materiality }) => {
    switch (lowerCase(materiality)) {
      case 'very low':
        return '#008535';
      case 'low':
        return '#3F9800';
      case 'medium':
        return '#70AD00';
      case 'high':
        return '#F5870A';
      case 'very high':
        return '#F5350A';
      default:
        return 'gray';
    }
  }};
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colorWhite};
`;
