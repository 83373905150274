import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.colorGray100};
`;

export const Header = styled.thead`
  background-color: ${({ theme }) => theme.colorGray100};
`;

export const HeaderRow = styled.tr`
  background-color: rgb(202, 232, 250);
`;

export const HeaderCell = styled.th`
  padding: .5rem;
  border: 1px solid ${({ theme }) => theme.colorGray100};
`;

export const Body = styled.tbody`
  padding: 5rem;
`;

export const Row = styled.tr`
  
`;

export const Cell = styled.td`
  padding: .5rem;
  border: 1px solid ${({ theme }) => theme.colorGray100};
`;

export const CellCentered = styled(Cell)`
  text-align: center;
`;

export const Blurred = styled.tr`
  filter: blur(5px);
`;