import React, {useEffect} from 'react';
import {CenteredContainer, Highlighted} from "../styled"
import {ButtonPrimary, H1, WrapperContent} from "../../shared/GlobalComponents";
import {useDispatch, useSelector} from "react-redux";
import {getImpactsAndDependencies} from "../../../store/reducers/analysisSlice";
import ImpactsTable from "../ImpactsTable";
import {Column, TwoColumnContainer} from "../../NACEAssessment/styled";
import ESRTable from "../ESRTable";
import {useNavigate} from "react-router";
import RequestStatus from "../../../constants/RequestStatus";
import LoadingSpinner from "../../spinner";

const Step5 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getImpactsAndDependencies());
  }, [dispatch])

  const analysisState = useSelector(state => state.analysis);
  const requestStatus = analysisState.getImpactsAndDependenciesRequestStatus;

  if (!analysisState) {
    //return <div>Data is empty...</div>;
  }

  const handleButtonClick = async () => {
    navigate('/assessment');
  }

  return (
    <>
      <WrapperContent>
        <CenteredContainer>
          <H1>Your likely <Highlighted>impacts and dependencies</Highlighted> are:</H1>
          <ButtonPrimary onClick={handleButtonClick}>Continue to app</ButtonPrimary>
          <TwoColumnContainer>
            <Column>
              <ESRTable results={analysisState.sustainabilityTopics}/>
            </Column>
            <Column>
              <ImpactsTable
                key={'impacts'}
                results={analysisState.likelyImpacts}
                impactHeader={(<><Highlighted>Likely Impacts</Highlighted> to nature and people</>)}
              />
              <ImpactsTable
                key={'dependencies'}
                results={analysisState.likelyDependencies}
                impactHeader={(<><Highlighted>Likely Dependencies</Highlighted> on Ecosystem Services </>)}
              />
            </Column>
          </TwoColumnContainer>
        </CenteredContainer>
      </WrapperContent>
      {requestStatus === RequestStatus.Loading && <LoadingSpinner/>}
    </>
  );
};

export default Step5;