import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CodeDropdownSearch from "../NACEAssessment/CodeDropdownSearch";
import {Body, Cell, CellCentered, Row, Table} from "../AnalysisForm/ImpactsTable/styled";
import {AddButtonContainer} from "../AnalysisForm/Step1/styled";
import AddButton from "../AnalysisForm/Step1/AddButton";
import {updateUser, setCodes} from "../../store/reducers/userSlice";
import {TABS} from '../../constants/tabConstants';
import {H2} from "../shared/GlobalComponents";

const TAB_TITLES = {
  [TABS.UPSTREAM]: 'Upstream Value Chain',
  [TABS.GROUP]: 'Operations',
  [TABS.DOWNSTREAM]: 'Downstream Value Chain',
};

const OperationGroup = ({group}) => {

  const dispatch = useDispatch();

  const [naceCodes, setNaceCodes] = useState([]);
  const [prodcomCodes, setProdcomCodes] = useState([]);
  const [cnCodes, setCnCodes] = useState([]);

  const userState = useSelector(state => state.user);

  useEffect(() => {

    let values = userState[group + 'Codes'] ?? [];

    if (group === TABS.GROUP && values.length < 1) {
      values = [userState.naceCode];
    }

    setNaceCodes(values);
    setProdcomCodes(userState.groupProdcomCodes ?? []);
    setCnCodes(userState.groupCnCodes ?? []);

    console.log('Updating user', userState);

  }, [group, userState]);

  const handleSelectNace = (key, value) => {

    const codes = [...naceCodes];
    codes[key] = value.key;

    setNaceCodes(codes);

    dispatch(setCodes({
      type: group,
      codes: codes
    }));

    dispatch(updateUser({
      [group + 'Codes']: codes
    }));

  };

  const handleSelectProdcom = (key, value) => {

    const codes = [...prodcomCodes];
    codes[key] = value.key;

    setProdcomCodes(codes);

    dispatch(updateUser({
      prodcom: codes
    }));

  };

  const handleSelectCn = (key, value) => {

    const codes = [...cnCodes];
    codes[key] = value.key;

    setCnCodes(codes);

    dispatch(updateUser({
      cn: codes
    }));

  };

  const onAddNacePress = () => {

    const codes = [...naceCodes];
    codes.push(null);

    setNaceCodes(codes);

    dispatch(setCodes({
      type: group,
      codes: codes
    }));

  }

  const onAddProdcomPress = () => {

    const codes = [...prodcomCodes];
    codes.push(null);

    setProdcomCodes(codes)

  }

  const onAddCnPress = () => {

    const codes = [...cnCodes];
    codes.push(null);

    setCnCodes(codes)

  }

  const onDeleteNace = (index) => {

    const codes = [...naceCodes];

    codes.splice(index, 1);

    dispatch(setCodes({
      type: group,
      codes: codes
    }));

    dispatch(updateUser({
      [group + 'Codes']: codes
    }))

  }

  const onDeleteProdcom = (index) => {

    const codes = [...prodcomCodes];

    codes.splice(index, 1);

    dispatch(updateUser({
      prodcom: codes
    }));

  }

  const onDeleteCn = (index) => {

    const codes = [...cnCodes];

    codes.splice(index, 1);

    dispatch(updateUser({
      cn: codes
    }));

  }

  const titlePrefix = TAB_TITLES[group] + ' - ' || '';

  return (

    <>
      <H2>{titlePrefix}NACE codes</H2>
      <Table>
        <Body>
          {
            naceCodes.map((code, index) => {
              let displayedCode = code ? code.slice(1, -1) : '';
              return (
                <Row key={'nace-' + index}>
                  <Cell>
                    <CodeDropdownSearch
                      value={code}
                      onSelect={value => {
                        handleSelectNace(index, value)
                      }}
                      codes={'nace'}
                    />
                  </Cell>
                  <Cell>
                    NACE 2.0 {displayedCode}
                  </Cell>
                  <CellCentered>
                    <button
                      style={{color: 'red', cursor: 'pointer'}}
                      onClick={() => onDeleteNace(index)}
                    >
                      X
                    </button>
                  </CellCentered>
                </Row>
              )
            })
          }
        </Body>
      </Table>

      <AddButtonContainer>
        <AddButton onPress={onAddNacePress} text={'Add NACE code'}/>
      </AddButtonContainer>

      {group === TABS.GROUP &&
      <>

        <H2>{titlePrefix}Products and services</H2>
        <Table>
          <Body>
            {
              prodcomCodes.map((code, index) => {
                let displayedCode = code ? code.slice(1, -1) : '';
                return (
                  <Row key={'prodcom-' + index}>
                    <Cell>
                      <CodeDropdownSearch
                        value={code}
                        onSelect={value => {
                          handleSelectProdcom(index, value)
                        }}
                        codes={'prodcom'}
                      />
                    </Cell>
                    <Cell>
                      PRODCOM 2022 {displayedCode}
                    </Cell>
                    <CellCentered>
                      <button
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => onDeleteProdcom(index)}
                      >
                        X
                      </button>
                    </CellCentered>
                  </Row>
                )
              })
            }
          </Body>
        </Table>

        <AddButtonContainer>
          <AddButton onPress={onAddProdcomPress} text={'Add Product or a service'}/>
        </AddButtonContainer>

        <H2>{titlePrefix}Tangible goods</H2>
        <Table>
          <Body>
            {
              cnCodes.map((code, index) => {
                let displayedCode = code ? code.slice(1, -1) : '';
                return (
                  <Row key={'cn-' + index}>
                    <Cell>
                      <CodeDropdownSearch
                        value={code}
                        onSelect={value => {
                          handleSelectCn(index, value)
                        }}
                        codes={'cn'}
                      />
                    </Cell>
                    <Cell>
                      CN 2023 {displayedCode}
                    </Cell>
                    <CellCentered>
                      <button
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => onDeleteCn(index)}
                      >
                        X
                      </button>
                    </CellCentered>
                  </Row>
                )
              })
            }
          </Body>
        </Table>

        <AddButtonContainer>
          <AddButton onPress={onAddCnPress} text={'Add Product or a service'}/>
        </AddButtonContainer>
      </>
      }
    </>

  )
}

export default OperationGroup;