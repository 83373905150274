import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {CenteredContainer, Label} from '../styled'
import {ButtonPrimary, H1, WrapperContent} from "../../shared/GlobalComponents";
import CodeDropdownSearch from "../../NACEAssessment/CodeDropdownSearch";
import { setNaceCode } from '../../../store/reducers/analysisSlice';
import {CheckboxContainer, CheckboxLabel} from "./styled";

const Step1 = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nace, setNace] = useState("");
  const [isGroup, setIsGroup] = useState(false);

  const handleSelect = (item) => {
    setNace(item.key);
  };

  const handleNext = () => {

    dispatch(setNaceCode({nace, isGroup}));

    if ( isGroup ) {
      navigate("/analysis/1-1");
    } else {
      navigate("/analysis/2");
    }

  }

  return(
    <WrapperContent>
      <CenteredContainer>
        <H1>What does your company do?</H1>
        <Label htmlFor="nace-code">Please select your NACE code or business activity:</Label>
        <CodeDropdownSearch onSelect={handleSelect}/>
        <ButtonPrimary type="button"
                value="submit "
                onClick={handleNext}
                disabled={!nace}>Next</ButtonPrimary>
        <CheckboxContainer>
          <input type="checkbox" onChange={() => {
            setIsGroup(!isGroup);
          }} checked={isGroup} />
          <CheckboxLabel>We are a group of companies</CheckboxLabel>
        </CheckboxContainer>
        <H1>Products and services</H1>
        <Label htmlFor="prodcom-code">Only for registred users</Label>
        <H1>Tangible goods</H1>
        <Label htmlFor="cn-code">Only for registred users</Label>
      </CenteredContainer>
    </WrapperContent>
  )
}

export default Step1;