import styled from 'styled-components';

export const StyledNavBar = styled.nav`
  padding: 0.25rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colorGray500};
  background-color:  ${({ theme }) => theme.colorPrimary};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 24px;
  width: auto;
`;

export const NavLinks = styled.div`
  display: flex;
  gap: .5rem;
`;

export const BackgroundWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorPrimary};
`;
