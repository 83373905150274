import React from "react";
import {P} from "../shared/GlobalComponents";
import ProcessingSpinner from "../shared/ProcessingSpinner";
import ImpactMaterialityGraph from "./ImpactMaterialityGraph";
import ImpactMaterialityTable from "./ImpactMaterialityTable";

const ImpactMateriality = ({ results }) => {
  if (!results || Object.keys(results).length === 0) {
    return <p>No results.</p>;
  }
  return (
    <>
      {Object.entries(results).sort().map(([naceCode, data]) => {
        let displayedCode = naceCode ? naceCode.slice(1, -1) : '';
        let description = data.impacts_and_dependencies['nace_rev2_description'];
        return (
          <>
            <P>NACE {displayedCode}: {description}</P>
            {data.processing && <ProcessingSpinner text={"Processing... (This could take some minutes)"}/>}
            {!data.processing && <ImpactMaterialityGraph doubleMateriality={data.double_materiality}/>}
            {!data.processing && <ImpactMaterialityTable doubleMateriality={data.double_materiality}/>}
          </>
        );
      })}
    </>
  );
}

export default ImpactMateriality;